import {
  any, object, string,
} from 'prop-types';
import dynamic from 'next/dynamic';

import { useI18n } from 'context/i18n';
import Layout from 'components/layout';
import { HeadSEO } from 'components/base';
import { replaceUndefinedKeysForNull, COOKIES } from 'utils/helpers';
import {
  initCMS, getCmsModule, getHomeCMSProps, getMetaCMSProps,
} from 'utils/cms';
import { IS_CAMPER } from 'utils/constants/system';
import ProductsRow from 'components/ProductsRow';
import Product from 'components/product';

const HomeComponent = IS_CAMPER ? dynamic(
  () => import('@camper/react-web-components').then((res) => res.PAGES.HomeComponent),
) : dynamic(
  () => import('@nnormal/react-web-components').then((res) => res.PAGES.HomeComponent),
);
const defaultTitle = IS_CAMPER ? 'Camper Shoes - Official Online Shop' : 'Nnormal Shoes - Official Online Shop';

const slots = {
  products: ProductsRow,
  product: Product,
};
export default function Home({
  defaultProps, homeProps, metaTags,
}) {
  const { locale } = useI18n();

  return (
    <Layout
      {...defaultProps}
      breadcrumbs={false}
      scrollArrow={true}
      extratags={[ <link key='canonical' rel="canonical" href={`${process.env.INTEGRA_DOMAIN}/${locale}`} /> ]}
    >
      <div>
        <HeadSEO
          title={metaTags?.pageTitle || defaultTitle}
          description={metaTags?.metaDescription || 'Buy new shoes, boots, sneakers and accessories from the collection. Contemporary designs, original spirit.  Free shipping and 2 years warranty.'}
          pageName={'home'}
        />
        <HomeComponent {...homeProps} slots={slots} />
      </div>
    </Layout>
  );
}

// Home.propTypes = {
//   lang: string,
//   locale: string,
//   defaultLocale: string,
//   footerProps: any,
//   tnmProps: any,
//   homeProps: any,
//   ribbon: any,
//   metaTags: object,
// };

Home.i18nPage = 'Home';

export async function getServerSideProps(context) {
  const locale = [ 'default', 'int' ].includes(context.locale) ? 'es_ES' : context.locale;
  const [ { defaultProps }, homeProps, metaTags ] = await Promise.all([
    initCMS({ context, locale }),
    getHomeCMSProps({ locale }),
    getMetaCMSProps({ locale, slug: '' }),
  ]);

  return {
    props: {
      locale: context.locale,
      defaultLocale: context.defaultLocale,
      defaultProps,
      homeProps,
      metaTags,
    },
  };
}
