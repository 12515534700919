import { getRibbon } from '../actions/general';
import { getMarket, getLang, replaceUndefinedKeysForNull, deleteEmptyKeys } from './helpers';
import { IS_CAMPER, IS_NNORMAL } from './constants/system';
import { TIER_POSITIONS } from './constants/cms';
import { logError } from './error';

// this variable is null or the return of the import for @camper/react-web-components or @nnormal/react-web-components
/**
 * @type {import('@camper/react-web-components') | import('@nnormal/react-web-components') | null}
 */
let CmsModule = null;
/**
 * This function is used to import the CMS module depending on the shop.
 */
export async function getCmsModule() {
  if (CmsModule === null) {
    CmsModule = IS_CAMPER ? await import('@camper/react-web-components') : await import('@nnormal/react-web-components');
  }
  // IS_CAMPER ? @type {import('@camper/react-web-components')} : await import('@nnormal/react-web-components');
  return CmsModule;
}

export function prepareCmsContainer({ locale, libContainer }) {
  const cmsContainer = libContainer.prepare({
    accessToken: '',
    country: getMarket(locale),
    language: getLang(locale),
    urlBase: process.env.INTEGRA_DOMAIN,
    showUnpublishedContent: false,
  });
  return cmsContainer;
}

export const getTiers = (tiersRs) => {
  if (!tiersRs) return {};
  const tierPositionValues = Object.values(TIER_POSITIONS);
  return tiersRs.reduce((acc, tier) => {
    if (tierPositionValues.includes(tier.tierPosition)) {
      if (tier.tierPosition === TIER_POSITIONS.GRID) {
        if (!acc[tier.tierPosition]) {
          acc[tier.tierPosition] = [];
        }
        acc[tier.tierPosition].push(tier);
      } else {
        acc[tier.tierPosition] = tier;
      }
    }
    return acc;
  }, {});
};

/**
 * Transforma un array agrupandolo por varios campos, pasadno de esto:
 * [
 *  { code: 'onxl', type: 'collection', target: 'W', family: 1 },
 *  { code: 'onar', type: 'collection', target: 'W', family: 1 },
 *  { code: 'onpe', type: 'collection', target: 'W', family: 1 },
 *  { code: 'onwa', type: 'collection', target: 'W', family: 1 }
]
 *
 * a esto:
 *
 * [
 *  {
 *    type: 'collection',
 *    target: 'W',
 *    family: '1',
 *    code: [ 'onxl', 'onar', 'onpe', 'onwa' ]
 *  }
 * ]
 */
const reArrangeCollections = (collectionsParams) => {
  if (collectionsParams === null) {
    return null;
  }

  const newColMap = {};
  for (let i = 0; i < collectionsParams.length; i++) {
    const colParam = collectionsParams[i];
    const key = `${colParam.type}|${colParam.target}|${colParam.family}`;
    if (newColMap[key] === undefined) {
      newColMap[key] = [];
    }
    newColMap[key].push(colParam.code);
  }

  // Ejemplo de lo que tendremos en `newColMap` cuando lleguemos aquí:
  //   { 'collection|W|1': [ 'onxl', 'onar', 'onpe', 'onwa' ] }

  const newColParams = [];
  Object.entries(newColMap).forEach((value) => {
    const key = value[0];
    const code = value[1];
    const [type, target, family] = key.split('|');

    newColParams.push({
      type,
      target,
      family: Number(family),
      code,
    });
  });

  return newColParams;
};

async function getTierParams({ cmsContainer, organisms, tierParams }) {
  if (tierParams && tierParams.length > 0) {
    try {
      if (IS_CAMPER) {
        const props = await cmsContainer.resolve(organisms.TierPropsService).getPageProps(tierParams[0], tierParams[1], tierParams[2], tierParams[3]);
        return getTiers(props);
      }
      const props = await cmsContainer.resolve(organisms.TierBannerPropsService).getPageProps(...tierParams);
      return getTiers(props);
    } catch (e) {
      console.error('Error in CMS -> Failed to load tier props', e);
    }
  }
  return null;
}

async function getCollectionParams({ cmsContainer, organisms, collectionsParams }) {
  if (collectionsParams !== null && collectionsParams.length > 0) {
    collectionsParams = reArrangeCollections(collectionsParams);

    const collectionTiers = await Promise.all(
      collectionsParams.map((colParam) => {
        const camperCodes = Array.isArray(colParam.code) ? colParam.code : [colParam.code];
        let tiers = Promise.resolve(undefined);
        try {
          if (IS_CAMPER) {
            tiers = cmsContainer.resolve(organisms.TierPropsService).getPageProps(colParam.type, camperCodes, colParam.target, colParam.family);
          } else {
            tiers = cmsContainer.resolve(organisms.TierBannerPropsService).getPageProps(colParam.type, camperCodes, colParam.target, colParam.family);
          }
        } catch (e) {
          const date = new Date();
          console.error(
            `${date.toLocaleDateString()} ${date.toLocaleTimeString()} -> ` +
              `Error in CMS -> Failed to load ${camperCodes} -> RQ / ${e?.config?.method} -> ${e?.config?.url}`,
          );
          return { camperCodes };
        }
        return tiers;
      }),
    );
    return collectionTiers.flat().filter((tier) => tier !== null);
  }
  return null;
}

async function getBanners({ bannerParams, container, MOLECULES }) {
  if (Array.isArray(bannerParams) && bannerParams.length > 0) {
    try {
      const params = await Promise.all(
        bannerParams.map(async (bannerParam) => {
          const result = await container.resolve(MOLECULES.BannerPropsService).getProps(bannerParam);
          return [bannerParam, result];
        }),
      );
      return Object.fromEntries(params);
    } catch (error) {
      const date = new Date();
      console.error(
        `${date.toLocaleDateString()} ${date.toLocaleTimeString()} -> Error in CMS -> Failed to load banners -> RQ / ${error?.config?.method} -> ${error?.config?.url}`,
      );
    }
  }
  return {};
}

async function getCmsComponentProps({ propsToLoad, container, organisms }) {
  const props = await Promise.all(
    propsToLoad.map(async ({ name }) => {
      try {
        const isTnm = name === 'tnmProps';
        const componentProps =
          !isTnm ? await container.resolve(organisms.FooterPropsService).getProps() : await container.resolve(organisms.TNMPropsService).getProps();
        replaceUndefinedKeysForNull(componentProps);
        return [name, componentProps];
      } catch (e) {
        const date = new Date();
        console.error(
          `${date.toLocaleDateString()} ${date.toLocaleTimeString()} -> Error in CMS -> Failed to load ${name} -> RQ / ${e?.config?.method} -> ${e?.config?.url}`,
        );
        return [name, null];
      }
    }),
  );
  return props;
}

async function getRibbonProps({ context, profile }) {
  try {
    const ribbonRs = await getRibbon({ context, profile });
    const hasHeaderRibbon10 = ribbonRs.headerRibbonDetailResponse.filter((item) => item.anchorId === 'header.ribbon.10').length > 0;

    let sortedRibbonDetail = ribbonRs.headerRibbonDetailResponse;

    if (hasHeaderRibbon10) {
      sortedRibbonDetail = ribbonRs.headerRibbonDetailResponse?.sort((item) => {
        if (item.anchorId === 'header.ribbon.10') {
          return -1;
        }

        return 0;
      });
    }

    return sortedRibbonDetail;
  } catch (e) {
    const date = new Date();
    console.error(`${date.toLocaleDateString()} ${date.toLocaleTimeString()} -> Error in API Ribbon -> RQ / ${e?.config?.method} -> ${e?.config?.url}`);
    return null;
  }
}

async function getSeoContentProps({ seoContent, container, molecules }) {
  if (seoContent?.target) {
    try {
      const seoContentRs = await container.resolve(molecules.GridPropsService).getProps(seoContent.target);
      if (seoContentRs) {
        return seoContentRs;
      }
    } catch (error) {
      logError(error);
    }
  }
  return null;
}

async function getPdpParams({ pdpParams, container, molecules }) {
  if (pdpParams.length > 0) {
    try {
      return await container.resolve(molecules.PdpDynamicComponentPropsService).getProps(...pdpParams);
    } catch (e) {
      const date = new Date();
      console.error(
        `${date.toLocaleDateString()} ${date.toLocaleTimeString()} -> Error in CMS -> Failed to load pdpProps -> RQ / ${e?.config?.method} -> ${e?.config?.url}`,
      );
    }
  }
  return null;
}

async function getKboixTipProps({ kboixTip, container, molecules }) {
  if (IS_NNORMAL && kboixTip !== null) {
    try {
      return await container.resolve(molecules.KboixDynamicComponentPropsService).getProps(kboixTip);
    } catch (e) {
      const date = new Date();
      console.error(
        `${date.toLocaleDateString()} ${date.toLocaleTimeString()} -> Error in CMS -> Failed to load kboixTip -> RQ / ${e?.config?.method} -> ${e?.config?.url}`,
      );
    }
  }
  return null;
}

async function getPopupProps({ id, container, fetcher }) {
  try {
    const popupProps = await container.resolve(fetcher).getProps(id);
    deleteEmptyKeys(popupProps);
    return popupProps;
  } catch (e) {
    // no logueemos porque simplemente puede que no haya popup configurado. Si hay un error, descomentar el log
    // const date = new Date();
    // console.error(`${date.toLocaleDateString()} ${date.toLocaleTimeString()} -> Error in CMS -> Failed to load popupProps -> RQ / ${e?.config?.method} -> ${e?.config?.url
  }
  return null;
}

/**
 * @typedef {Object} InitCmsParams
 * @property {Object} context
 * @property {string} locale
 * @property {Object | null} config
 * @property {string[]} tierParams
 * @property {string[]} collectionsParams
 * @property {string} tnmSelectedTarget
 * @property {string[]} bannerParams
 * @property {string[]} pdpParams
 * @property {any} seoContent
 * @property {string | null} kboixTip
 */
/**
 * @param {InitCmsParams} params
 */
export async function initCMS({
  context,
  locale,
  config = null,
  tierParams = [],
  collectionsParams = [],
  bannerParams = [],
  seoContent,
  pdpParams = [],
  kboixTip = null,
}) {
  let propsToLoad = [
    { name: 'tnmProps', component: 'Tnm' },
    { name: 'footerProps', component: 'Footer' },
  ];
  if (config) {
    propsToLoad = propsToLoad.filter((item) => !(config[item.name] === false));
  }

  const returnObj = {
    defaultProps: {},
  };

  const { CMSContainer, ORGANISMS, MOLECULES, ATOMS } = await getCmsModule();

  const cmsContainer = prepareCmsContainer({ locale, libContainer: CMSContainer });

  const [tiers, collectionTiers, banners, components, ribbonProps, seoContentProps, pdpProps, kboixTipProps, popupProps] = await Promise.all([
    getTierParams({ cmsContainer, organisms: ORGANISMS, tierParams }),
    getCollectionParams({ cmsContainer, organisms: ORGANISMS, collectionsParams }),
    getBanners({ bannerParams, container: cmsContainer, MOLECULES }),
    getCmsComponentProps({ propsToLoad, container: cmsContainer, organisms: ORGANISMS }),
    getRibbonProps({ context, profile: locale }),
    getSeoContentProps({ seoContent, container: cmsContainer, molecules: MOLECULES }),
    getPdpParams({ pdpParams, container: cmsContainer, molecules: MOLECULES }),
    getKboixTipProps({ kboixTip, container: cmsContainer, molecules: MOLECULES }),
    getPopupProps({ container: cmsContainer, fetcher: ATOMS.PopupPropsService }),
  ]);

  console.log('response', pdpProps);

  returnObj.tiers = tiers;
  if (collectionTiers) {
    if (Array.isArray(collectionTiers) && collectionTiers.length > 0) {
      collectionTiers.forEach((collectionTier) => {
        returnObj.tiers[collectionTier.camperCode] = getTiers([collectionTier]);
      });
    } else if (Array.isArray(collectionTiers?.camperCodes) && collectionTiers?.camperCodes === 0) {
      collectionTiers?.camperCodes.forEach((camperCode) => {
        if (returnObj && returnObj.tiers && camperCode in returnObj.tiers) {
          returnObj.tiers[camperCode] = null;
        }
      });
    }
  }
  returnObj.banners = banners;
  components.forEach(([name, componentProps]) => {
    returnObj.defaultProps[name] = componentProps;
  });

  if (ribbonProps !== null) {
    returnObj.defaultProps.ribbon = ribbonProps;
  }
  returnObj.cmsSeoContent = seoContentProps;

  if (pdpProps !== null) {
    returnObj.pdpProps = pdpProps;
  }
  if (kboixTipProps !== null) {
    returnObj.kboixTip = kboixTipProps;
  }
  if (popupProps !== null) {
    returnObj.defaultProps.popupProps = popupProps;
  }

  const json = JSON.parse(JSON.stringify(returnObj));

  return json;
}

export async function getMetaCMSProps({ locale, slug }) {
  let metaTags = null;
  try {
    const { CMSContainer, ORGANISMS } = await getCmsModule();
    const cmsContainer = prepareCmsContainer({ locale, libContainer: CMSContainer });
    try {
      metaTags = await cmsContainer.resolve(ORGANISMS.MetaPropsService).getProps(slug);
    } catch (error) {
      console.error(`Failed to load metatags. Error: ${error}`);
    }
  } catch (error) {
    console.error('Error in CMS -> Failed to load meta props', error);
  }
  return metaTags;
}

export async function getHomeCMSProps({ locale }) {
  let homeProps = {};
  try {
    const { CMSContainer, PAGES } = await getCmsModule();

    const cmsContainer = prepareCmsContainer({ locale, libContainer: CMSContainer });
    homeProps = await cmsContainer.resolve(PAGES.HomePropsService).getProps();

    replaceUndefinedKeysForNull(homeProps);
  } catch (error) {
    console.error('Error in CMS -> Failed to load home props', error);
  }
  return homeProps;
}

export async function getLandingCMSProps({ locale, slug }) {
  let redirect = null;
  let contentProps = null;

  try {
    const { CMSContainer, PAGES } = await getCmsModule();
    const cmsContainer = prepareCmsContainer({ locale, libContainer: CMSContainer });
    const fetcher = IS_NNORMAL ? cmsContainer.resolve(PAGES.DynamicLandingPropsService) : cmsContainer.resolve(PAGES.LandingPropsService);
    contentProps = await fetcher.getProps(slug);
    const redirectCamper = contentProps?.props?.redirect === true && typeof contentProps?.props?.url === 'string';
    const redirectNnormal = contentProps?.redirect === true && typeof contentProps?.url === 'string';
    if (redirectCamper || redirectNnormal) {
      redirect = {
        destination: `/${locale}${redirectNnormal ? contentProps?.url : contentProps?.props?.url}`,
        permanent: true,
      };
    }
  } catch (error) {
    // console.error('Error in CMS -> Failed to load landing props', error);
  }
  replaceUndefinedKeysForNull(contentProps);
  return {
    redirect,
    contentProps,
  };
}
